#root {
  height: 100vh;
  /* font-family: "Montserrat", sans-serif; */
}

/* Initialisation */
.launch-screen {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  background-color: #1f1a1b !important;
  font-family: Tahoma, "Trebuchet MS", sans-serif;
}

/* sider trigger */
.ant-layout-sider-trigger {
  background-color: #1f1a1b !important;
}

/* dropdown menus */

.ant-dropdown-menu-item:hover {
  color: #007980;
}

/* select item */

.ant-select-item-option-content:hover {
  color: #007980;
}

.ant-card-head-title {
  white-space: normal;
}
